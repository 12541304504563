import axios from 'axios';
import Cookies from 'js-cookie';
import { get_request, post_request } from './request';
import { toast } from 'react-toastify';
export const get_plans = async () => {
    try {
        let { data, status } = await get_request("/plans", false);
        let formatted_data = data.map((plan) => {
            let formatted_plan = {
                ...plan,
                maximum_amount: (plan.maximum_amount / 100000000).toFixed(2),
                minimum_amount: (plan.minimum_amount / 100000000).toFixed(2),
            }
            return formatted_plan;
        });
        return { data: formatted_data, status };
    } catch (e) {
        console.log(e);
        return { data: null, status: 500 };
    }
}

export const get_transactions = async () => {
    try {
        let { data, status } = await get_request("/transactions", true);

        let formatted = data.map((transaction) => {
            let formatted_transaction = {
                ...transaction,
                amount: (transaction.amount / 100000000).toFixed(2),
                created_at: new Date(transaction.created_at * 1000).toDateString(),
            }
            return formatted_transaction
        })
        return { data: formatted, status };
    } catch (e) {
        console.log(e);
        return { data: null, status: 500 };
    }
}

export const get_plan = async (id) => {
    try {
        let { data, status } = await get_request(`/plan/${id}`, false);
        return { data, status };
    } catch (e) {
        console.log(e);
        return { data: null, status: 500 };
    }
}

export const get_earnings = async (position_id) => {
    try {
        let body = {
            position_id: position_id
        }
        let { data, status } = await post_request("/get_earnings", body, true);
        return { data, status };
    } catch (e) {
        console.log(e);
        return { data: null, status: 500 };
    }
}

export const get_positions = async () => {
    try {
        let { data, status } = await get_request("/positions", true);
        // format the data 
        let formatted_data = data.map((position) => {
            let formatted_position = {
                ...position,
                created_at: new Date(position.created_at).toDateString(),
                reward_per_cycle: (position.reward_per_cycle / 100000000).toFixed(2),
                capital: (position.capital / 100000000).toFixed(2),

            }
            return formatted_position;
        });
        return { data: formatted_data, status };
    } catch (e) {
        console.log(e);
        return { data: null, status: 500 };
    }
}
export const register_user = async (body) => {
    try {
        let { data, status } = await post_request("/register", body, false);
        return { data, status };
    } catch (e) {
        console.log(e);
        return { data: null, status: 500 };
    }
}

export const login_user = async (body, state) => {
    try {
        let { data, status } = await post_request("/login", body, false);
        let token = data.token;
        Cookies.set("token", token);
        state(true);
        return { data, status };
    } catch (e) {

        toast.error(e.response.data.message);
        console.log("s",e);
        return { data: null, status: 500 };
    }
}

export const get_config = async () => {
    try {
        let { data, status } = await get_request("/config", false);
        return { data, status };
    } catch (e) {
        console.log(e);
        return { data: null, status: 500 };
    }
}

export const get_data = async () => {
    try {
        let { data, status } = await get_request("/data", true);
        data["balance"] = (data.balance / 100000000).toFixed(2);
        return { data, status };
    } catch (e) {
        console.log(e);
        return { data: null, status: 500 };
    }
}

// pub amount: f64,
// pub currency_id: String,

export const deposit = async (body) => {
    // pub amount: f64,
    // pub currency_id: String,
    try {
        let { data, status } = await post_request("/deposit/new", body, true);
        return { data, status };
    } catch (e) {
        console.log(e);
        return { data: null, status: 500 };
    }
}

export const open_position = async (body) => {
    try {
        let { data, status } = await post_request("/position/open", body, true);
        return { data, status };
    } catch (e) {
        return { data: e, status: 500 };
    }
}




export const get_frontend_info = async () => {
    let hostname = window.location.hostname;

    console.log("iS LOCAL", process.env);
    if (process.env.NODE_ENV === 'development') {
        hostname = "antrexcapital.com";
    }
    if (hostname.split(".").length > 2) {
        let length = hostname.split(".").length;
        hostname = hostname.split(".")[length - 2] + "." + hostname.split(".")[length - 1];
    }
    let soft_url = "https://sentinel.solidhash.io/peripheral-license/license/domain/";
    let url = soft_url + hostname;
    try {
        let { data: apiData } = await axios.get(url);

        let tenant_id = apiData.tenant_id;

        localStorage.setItem("tenant_id", tenant_id);
        let { data: config, status } = await get_config();
        if (!config) {
            console.log("No config found");
            return;
        }

        let fullData = {
            ...config,
            tenant_id: tenant_id
        }
        console.log("zzzzzzz", apiData);
        localStorage.setItem("frontendInfo", JSON.stringify(fullData));
        return fullData;
    } catch (e) {
        console.log(e);
    }
}