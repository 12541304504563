import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
export const get_request = async (endpoint, with_auth) => {
    try {
        let base_url = process.env.REACT_APP_BROKER_API;
        let headers = {
            "Content-Type": "application/json",
            "x-tenant-id": localStorage.getItem("tenant_id") || "frenetic",
            // Authorization: "Bearer " + user_id,
        };
        if (with_auth) {
            if (!Cookies.get("token")) {
                console.error("Cookie not set")
                return { data: null, status: 401 };
            }
            headers["Authorization"] = "Bearer " + Cookies.get("token");
        }

        let { data, status } = await axios.get(base_url + endpoint, {
            headers: headers,
        });
        return { data, status };
    } catch (e) {
        console.log(e);
        return { data: null, status: 500 };
    }
}

export const post_request = async (endpoint, data, with_auth) => {
    try {
        let base_url = process.env.REACT_APP_BROKER_API;
        let headers = {
            "Content-Type": "application/json",
            "x-tenant-id": localStorage.getItem("tenant_id") || "frenetic",
            // Authorization: "Bearer " + user_id,
        };
        if (with_auth) {
            if (!Cookies.get("token")) {
                console.error("Cookie not set")
                return { data: null, status: 401 };
            }
            headers["Authorization"] = "Bearer " + Cookies.get("token");
        }

        let { data: response_data, status } = await axios.post(base_url + endpoint, data, {
            headers: headers,
        });
        return { data: response_data, status };
    } catch (e) {
        console.log(e.response.data.message);

        toast.error(e?.response?.data?.message);
        return { data: e.response.data.message || "something went wrong", status: 500 };
    }
}