import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Navbar from "Layout/Navbar/Navbar";
import About from "pages/About/About";
import ContactPage from "pages/ContactPage/ContactPage";
import Signup from "pages/Authentication/Signup/Signup";
import AuthenticationLayout from "Layout/AuthenticationLayout/AuthenticationLayout";
import MainLayout from "Layout/MainLayout/MainLayout";
import ForgotPassword from "pages/Authentication/ForgotPassword/ForgotPassword";
import Login from "pages/Authentication/Login/Login";
import Dashboard from "pages/Dashboard/Dashboard";
import MyInvestments from "pages/Dashboard/MyInvestments/MyInvestments";
import DashBoardLayout from "Layout/DashBoardLayout/DashBoardLayout";
import Transactions from "pages/Dashboard/Transactions/Transactions";
import InvestmentsPlan from "pages/Dashboard/InvestmentsPlan/InvestmentsPlan";
import Referral from "pages/Dashboard/Referral/Referral";
import MyAccountModal from "Modals/Account/MyAccountModal/MyAccountModal";
import {
  get_data,
  get_frontend_info,
  get_plans,
  get_positions,
} from "helpers/api/apis";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RiskDisclosurePage from "pages/RiskDisclosure/Risk";
import AntiSpamPolicyPage from "pages/RiskDisclosure/AntiSpam";
import AntiMoneyLaunderingPage from "pages/RiskDisclosure/AntiMoney";
import PrivacyPolicyPage from "pages/RiskDisclosure/Privacy";
import HowToStartPage from "pages/RiskDisclosure/HowTo";
import TermsOfUsePage from "pages/RiskDisclosure/TermsOfUse";
import SmartsuppIntegration from "SmartSupp";
const App = () => {
  const [positions, setPositions] = useState([]);
  const [userData, setUserData] = useState({});
  const [frontendInfo, setFrontendInfo] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") == "true" ? true : false
  );

  const [reloadState, setReloadState] = useState(1);
  async function fetch_positios() {
    let { data, status } = await get_positions();
    if (status != 200) {
      localStorage.setItem("isLoggedIn", false);
      setIsLoggedIn(false);
      return;
    } else {
      localStorage.setItem("isLoggedIn", true);
      setIsLoggedIn(true);
    }
    setPositions(data);
    if (data?.length > 0) {
    }
  }

  async function fetch_frontend_info() {
    let data = await get_frontend_info();
    setFrontendInfo(data);
  }

  async function fetch_data() {
    let { data, status } = await get_data();
    if (status != 200) {
      localStorage.setItem("isLoggedIn", false);
      setIsLoggedIn(false);
      return;
    }
    setUserData(data);
    console.log(data);
  }
  const [plans, setPlans] = useState([]);
  async function fetch_plans() {
    let { data } = await get_plans();
    setPlans(data);
  }

  useEffect(() => {
    fetch_plans();
  }, []);

  useEffect(() => {
    fetch_frontend_info();
    fetch_positios();
    fetch_data();
  }, [reloadState]);
  return (
    <>
      <ToastContainer />
      <SmartsuppIntegration />
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              frontendInfo={frontendInfo}
            />
          }
        >
          <Route path="" element={<Home frontendInfo={frontendInfo} isLoggedIn={isLoggedIn} />} />
          <Route path="about" element={<About />} />
          <Route
            path="contact"
            element={<ContactPage frontendInfo={frontendInfo} />}
          />
          <Route
            path="risk-disclosure"
            element={<RiskDisclosurePage frontendInfo={frontendInfo} />}
          />
          <Route
            path="anti-spam"
            element={<AntiSpamPolicyPage frontendInfo={frontendInfo} />}
          />
          <Route
            path="anti-money-laundering"
            element={<AntiMoneyLaunderingPage frontendInfo={frontendInfo} />}
          />
          <Route
            path="privacy-policy"
            element={<PrivacyPolicyPage frontendInfo={frontendInfo} />}
          />
          <Route
            path="how-to-start"
            element={<HowToStartPage frontendInfo={frontendInfo} />}
          />
          <Route
            path="terms-of-use"
            element={<TermsOfUsePage frontendInfo={frontendInfo} />}
          />
        </Route>
        <Route path="/" element={<AuthenticationLayout />}>
          <Route
            path="login"
            element={
              <Login
                setIsLoggedIn={setIsLoggedIn}
                setReloadState={setReloadState}
              />
            }
          />
          <Route path="sign-up" element={<Signup frontendInfo={frontendInfo} />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>
        {isLoggedIn && (
          <Route
            path="/"
            element={
              <DashBoardLayout
                setIsLoggedIn={setIsLoggedIn}
                isLoggedIn={isLoggedIn}
              />
            }
          >
            <Route
              path="dashboard"
              element={
                <Dashboard
                  setIsLoggedIn={setIsLoggedIn}
                  userData={userData}
                  positions={positions}
                />
              }
            />
            <Route
              path="my-investments"
              element={<MyInvestments positions={positions} plans={plans} />}
            />
            <Route path="transaction" element={<Transactions />} />
            <Route
              path="investments-plan"
              element={<InvestmentsPlan plans={plans} userData={userData} />}
            />
            <Route path="referral" element={<Referral />} />
          </Route>
        )}
      </Routes>{" "}
      <MyAccountModal userData={userData} isActive={false} />
    </>
  );
};

export default App;
